/** @jsx jsx */
import Image from 'components/image';
import { LearnMore } from 'components/link';
import SectionHeading from 'components/section-heading';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Box, Container, Heading, Text, jsx } from 'theme-ui';


// const list = [
//   'PROACTIVE HIRING MODEL',
//   'EXPERIENCED SERVICE DELIVERY',
//   'DIGITAL TRANSFORMATION',
//   'HIGH QUALITY',
// ];

const AdelphalabsSolutions = () => {
    const data = useStaticQuery(graphql`
    query {
      ImageDeliveryEase: file(relativePath: { eq: "Delivery.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImagePunchlist: file(relativePath: { eq: "punchlist.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImageHandyman: file(relativePath: { eq: "handyman.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImageLinkrocket: file(relativePath: { eq: "Linkrocket.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImageSalesprophet: file(relativePath: { eq: "Salesprophet.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
    }
  `);
    return (
        <Box as="section" id="CaseStudies" sx={styles.section}>
            <Container>
                <SectionHeading
                    slogan="Labs"
                    title="Innovating Tomorrow's Solutions Today"
                    className="mb-5"
                />
                <Link
                    to="/deliveryease-platform"
                >
                    <Image
                        src={data.ImageDeliveryEase.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="DeliveryEase Image"
                        className="rounded"
                    />
                </Link>
                <div className='ps-xl-5 ps-lg-5 ps-md-3 ps-md-1 ms-xl-5 ms-lg-5 ms-md-3 ms-md-1 mb-5'>
                    <Heading sx={styles.title}>
                        DeliveryEase - Simplifying Pharmacy <br /> Delivery Operations
                    </Heading>
                    <Text as="p" sx={styles.summary}>
                        Discover how DeliveryEase simplifies pharmacy delivery operations, boosting efficiency <br /> and reducing administrative burdens.
                    </Text>
                    <LearnMore
                        path="/deliveryease-platform"
                        sx={{ mt: [3, null, null, 4] }}
                        label={'Read More'}
                    />
                </div>
                <Box sx={styles.grid} className='px-xl-5 px-lg-5 px-md-0 px-0 mx-lg-5 mx-xl-5 mx-md-0 mx-0 py-xl-5 py-lg-5 py-md-3 py-1 mt-xl-5 mt-lg-5 mt-md-3 mt-1'>
                    <Box sx={styles.illustration}>
                        <Image
                            src={data.ImagePunchlist.childImageSharp.gatsbyImageData}
                            loading="lazy"
                            alt="Punchlist Image"
                            className="rounded"
                        />
                    </Box>
                    <Box sx={styles.content}>

                        <Heading sx={styles.title}>
                            Punchlist - Simplifying Building Tasks
                        </Heading>
                        <Text as="p" sx={styles.summary}>
                            Punchlist brings all your building tasks into one simple view, allowing you to get them done faster. Streamline your workflow and manage tasks efficiently with Punchlist.
                        </Text>

                        <LearnMore
                            path="/punchlist"
                            sx={{ mt: [3, null, null, 4] }}
                            label={'Read More'}
                        />
                    </Box>
                </Box>
                <Box sx={styles.grid} className='px-xl-5 px-lg-5 px-md-0 px-0 mx-lg-5 mx-xl-5 mx-md-0 mx-0 py-xl-5 py-lg-5 py-md-3 py-1 mt-xl-5 mt-lg-5 mt-md-3 mt-1'>
                    <Box sx={styles.content}>
                        <Heading sx={styles.title}>
                            Easy Handyman Services Book Home Repairs
                        </Heading>
                        <Text as="p" sx={styles.summary}>
                            Handyman offers the simplest way to book a handyman service. Choose from a variety of services to get started and experience hassle-free booking like never before.
                        </Text>

                        <LearnMore
                            path="/handyman"
                            sx={{ mt: [3, null, null, 4] }}
                            label={'Read More'}
                        />
                    </Box>
                    <Box sx={styles.illustration}>
                        <Image
                            src={data.ImageHandyman.childImageSharp.gatsbyImageData}
                            loading="lazy"
                            alt="Handyman"
                        />
                    </Box>
                </Box>
                <Box sx={styles.grid} className='px-xl-5 px-lg-5 px-md-0 px-0 mx-lg-5 mx-xl-5 mx-md-0 mx-0 py-xl-5 py-lg-5 py-md-3 py-1 mt-xl-5 mt-lg-5 mt-md-3 mt-1'>
                    <Box sx={styles.illustration}>
                        <Image
                            src={data.ImageLinkrocket?.childImageSharp.gatsbyImageData}
                            loading="lazy"
                            alt="Linkrocket Image"
                        />
                    </Box>
                    <Box sx={styles.content}>

                        <Heading sx={styles.title}>
                            LinkRocket - Boost Your Website's Performance
                        </Heading>
                        <Text as="p" sx={styles.summary}>
                            Experience a surge in traffic and elevate your website's ranking with LinkRocket. Our comprehensive solution is designed to drive more visitors to your site and enhance your online presence.
                        </Text>

                        <LearnMore
                            path="/linkrocket"
                            sx={{ mt: [3, null, null, 4] }}
                            label={'Read More'}
                        />
                    </Box>
                </Box>
                <Box sx={styles.grid} className='px-xl-5 px-lg-5 px-md-0 px-0 mx-lg-5 mx-xl-5 mx-md-0 mx-0 py-xl-5 py-lg-5 py-md-3 py-1 mt-xl-5 mt-lg-5 mt-md-3 mt-1'>
                    <Box sx={styles.content}>
                        <Heading sx={styles.title}>
                            SalesProphet - Your Next Customer Awaits
                        </Heading>
                        <Text as="p" sx={styles.summary}>
                            Engage with leads effortlessly. SalesProphet offers 86M+ contacts for multi-channel sequences. Start growing your customer base today!
                        </Text>

                        <LearnMore
                            path="/salesprophet"
                            sx={{ mt: [3, null, null, 4] }}
                            label={'Read More'}
                        />
                    </Box>
                    <Box sx={styles.illustration}>
                        <Image
                            src={data.ImageSalesprophet.childImageSharp.gatsbyImageData}
                            loading="lazy"
                            alt="SalesProphet Image"
                            className="rounded"
                        />
                    </Box>
                </Box>
            </Container>
        </Box >
    );
};

export default AdelphalabsSolutions;

const styles = {
    section: {
        backgroundColor: '#f3f6fe',
        pt: [8, null, 12, 6, null, 15],
        pb: [null, null, null, 8, 0],
    },
    grid: {
        gap: [null, null, null, null, 12],
        display: ['flex', null, null, 'grid'],
        flexDirection: ['column-reverse', null, null, 'unset'],
        alignItems: 'center',
        gridTemplateColumns: ['1fr', null, null, null, '470px 1fr', '1fr 549px'],
    },
    illustration: {
        textAlign: 'center',
        position: 'relative',
        mt: [2, null, null, 0, 4, 0],
        img: {
            maxWidth: ['100%', null, null, null, null, '100%'],
        },
    },
    content: {
        marginTop: [null, null, null, null, null, -16],
        maxWidth: [null, null, null, 420, 560, 'none'],
        margin: [null, null, null, '0 auto', 'unset'],
        textAlign: ['center', null, null, null, 'left'],
        mb: 4
    },
    title: {
        color: '#09386b',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    summary: {
        color: 'textSecondary',
        fontFamily: 'headingAlt',
        fontSize: ['15px', null, null, 2, '15px', 4],
        lineHeight: [1.86, null, null, null, 1.86, 1.5],
        m: [null, null, null, '20px auto 0', '15px 0 0', null, 'unset'],
    },
    list: {
        gap: '0 18px',
        fontFamily: 'headingAlt',
        gridTemplateColumns: ['repeat(2, 142px)', null, null, 'repeat(2, 200px)'],
        justifyContent: [null, null, null, 'center', 'unset'],
        listStyle: 'none',
        display: 'block',
        mt: [4, null, null, 5, 4, 5],
        p: 0,
        li: {
            fontSize: [0, 1, null, 2, '15px', 2],
            fontWeight: 600,
            alignItems: 'center',
            color: 'textSecondary',
            fontFamily: 'headingAlt',
            display: 'flex',
            lineHeight: [2.81, null, null, null, 2.2, 2.81],
        },
    },
};
