import Layout from 'components/layout';
import Seo from 'components/seo';
import React from 'react';
import AdelphalabsSolutions from '../section-adelphalabs/adelphalabs';
import Banner from '../section-adelphalabs/banner';
import Partners from '../sections/partners';
import Subscribe from '../sections/subscribe';


export default function AdelphalabsPage() {
    return (
        <Layout>
            <Seo
                title="AdelphaTech"
                description="is a leading web & mobile application development firm based in Toronto, Canada that offers custom web, and mobile app development services."
            />
            <Banner />
            <Partners />
            <AdelphalabsSolutions />
            <Subscribe />
        </Layout>
    )
};
